// src/components/AuthComponent.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL } from "../apiURL";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import FAQPage from './FAQ';
import './landing.css'
var FormData = require('form-data');

const AuthComponent = () => {
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(true);
  const [page, setPage] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');

  const handleLoginClick = () => {
    setLogin(true);
    setPage(0);
  };

  const generateCaptcha = () => {
    var num1 = Math.floor(Math.random() * 10);
    var num2 = Math.floor(Math.random() * 10);
    const operator = ['+', '-', '*'][Math.floor(Math.random() * 3)];
    if(num2>num1){
      const temp =num1 
      num1=num2
      num2=temp
    }
    const question = `What is ${num1} ${operator} ${num2}?`;
    const answer = eval(`${num1} ${operator} ${num2}`);
    setCaptchaAnswer(answer.toString());

    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 50;
    const ctx = canvas.getContext('2d');
    ctx.font = '20px Arial';
    ctx.fillText(question, 10, 30);
    const dataURL = canvas.toDataURL();
    setCaptchaImage(dataURL);
  };
/*
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if(otp.length === 0){
      toast.error("Press Login button")
      return
    }
    // Perform login logic with phone number and OTP
    console.log('Logging in with Phone Number:', phoneNumber, 'and OTP:', otp);
    try {
      // Make the axios POST request to verify OTP
      const response = await axios.post(`${API_URL}/verify`, {
        phone: phoneNumber,
        otp: otp,
      });

      // Handle the response as needed
      console.log('OTP Verification Response:', response.data);
      if(response.data.message === "Match" ){
        console.log("SUCKS! You've been signed in!")
        // Set authentication status to true upon successful verification
        navigate("/home");
      }else{
        toast.error("Incorrect OTP");
      }

      

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  */
  const handleLogin = async (e) => {

    if(userAnswer !== captchaAnswer){
      toast.error("Incorrect captcha")
      return
    }

    if(phoneNumber.length !=10){
      toast.error("Please enter a valid phone number")
      return
    }
    
    try {
      // Make the axios POST request to login
      console.log("****************************************")
      const response = await axios.post(`${API_URL}/login`, {
        phone: phoneNumber
      });

      // Handle the response as needed
      console.log('Login Response:', response.data);
      if(response.data.message == "User not found."){
        console.log("No record Found")
        toast.error("No record Found. Check Phone number and retry.")
      }else{
        navigate("/home");
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' , padding: '15px'}}>
        <img src="/logo_0.png" alt="Header Image" style={{ height: '100px' }} />
      </div>
      <img src="/menu-bg.png" alt="Header Image" style={{ width: '100%', height: 'auto' }} />
      <Navbar variant="dark" style={{ backgroundColor: '#4c7c7c'}}>
        <Container>
          <Navbar.Brand href="/">Meghalaya Police Recruitment 2024</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={handleLoginClick}>Login</Nav.Link>
            {//<Nav.Link onClick={handleFAQClick}>FAQ</Nav.Link>
            }
            <Nav.Link href="https://megpolice.gov.in/sites/default/files/advertisement.pdf" > Advertisement </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
         <div style={{ display: 'flex',flexDirection:'column', alignItems: 'center', backgroundColor: '#dee0d7', padding: '50px', height: 'auto' }}>
          <Card className="shadow-sm" style={{ borderColor: "#000", maxWidth: "60rem", width: '100%', height: '50%'}}>
          <Card.Body>
            <br></br>
            <br></br>
        <form onSubmit={console.log("submitting")} className="w-50 mx-auto">
          <center>
            <h2 className="mb-4" style={{ fontWeight: 'bold' }}>Login</h2>
          </center>
          
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">Enter Phone Number:</label>
            <input type="tel" className="form-control" pattern="[0-9]*" id="phoneNumber" min = "10" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
            <br/>
            <label className="form-label">
              CAPTCHA:
              {captchaImage && <img src={captchaImage} alt="CAPTCHA" />}
              <input
                type="text"
                value={userAnswer}
                placeholder="Enter captcha answer"
                onChange={(e) => setUserAnswer(e.target.value)}
                required
              />
            </label>
            <Button
              variant="primary"
              onClick={handleLogin}
              style={{ backgroundColor: '#406666'}}
            >
              {'Login'}
            </Button>
          </div>
        </form>
        </Card.Body>
        </Card>
        
        <Card className="shadow-sm" style={{ borderColor: "#000", maxWidth: "60rem", width: '100%', height: '50%', marginTop: '1rem'}}>
        <br></br>
        <br></br>
        <Card.Body>
          <label>
            <strong>Note:</strong>
          </label>
            <ul style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
              <li>Candidates opting for Edit/Correction or change in PET date are requested to kindly submit an application to the Member Secretary, Central Recruitment Board before the commencement of the PET.</li>
              <li>Kindly contact the office of the Central Recruitment Board at Mawroh, Shillong or reach us through our helpline no.6033164273 on working days during office hours.</li>
            </ul>
        </Card.Body>
        </Card>
        </div>
    {
    <ToastContainer
    position="bottom-center"
    autoClose={2000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
    }
      </div>
    );
};

export default AuthComponent;
